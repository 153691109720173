/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.company-list::v-deep {
  margin-bottom: 32px;
}
.company-list::v-deep .company-card {
  width: 400px;
}
.mobile .company-list::v-deep .company-card {
  width: 100%;
}
.right-button {
  float: right;
}
